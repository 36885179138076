var createVolumeChart = function (elem) {
  var ctx = elem.getContext('2d'),
    volumeChartContainer = new Chart(ctx, {
      data: {
        datasets: [{
          backgroundColor: ['#ed1c2e', '#58595b', '#58595b', '#58595b'],
          borderWidth: 0,
          data: volumeChart.volumeChartData
        }],
        labels: volumeChart.volumeChartLabels
      },
      options: {
        legend: {
          display: false
        },
        scales: {
          xAxes: [{
            gridLines: {
              display: false
            },
            ticks: {
              callback: function () {
                return '';
              }
            }
          }],
          yAxes: [{
            gridLines: {
              display: false,
              zeroLineWidth: 0
            },
            ticks: {
              callback: function () {
                return '';
              }
            }
          }]
        },
        tooltips: {
          enabled: 
          false,
          callbacks: {
            label: function (tooltipItem, data) {
              return Number(data.datasets[0].data[tooltipItem.index], 10).toLocaleString('en');
            }
          }
        }
      },
      type: 'bar'
    });
};

var createTransactionChart = function (elem) {
  var ctx = elem.getContext('2d'),
    volumeTransactionContainer = new Chart(ctx, {
      data: {
        datasets: [{
          backgroundColor: ['#ed1c2e', '#58595b', '#58595b', '#58595b'],
          borderWidth: 0,
          data: transactionChart.transactionChartData,
          label: ''
        }],
        labels: transactionChart.transactionChartLabels
      },
      options: {
        legend: {
          display: false
        },
        scales: {
          xAxes: [{
            gridLines: {
              display: false
            },
            ticks: {
              callback: function () {
                return '';
              }
            }
          }],
          yAxes: [{
            gridLines: {
              display: false,
              zeroLineWidth: 0
            },
            ticks: {
              callback: function () {
                return '';
              }
            }
          }]
        },
        tooltips: {
          callbacks: {
            label: function (tooltipItem, data) {
              return ' ' + data.datasets[0].data[tooltipItem.index] + '%';
            }
          }
        }
      },
      type: 'bar'
    });
};

(function ($) {
  var Selzer = {
    'common': {
      init: function () {
        $('#main-nav').selzerMenu();

        $('.form-control-currency, .IDX-quicksearchForm .IDX-qsInput').on('keyup', function (evt) {
          var $this = $(this),
            input = $this.val();

          input = input.replace(/[\D\s\._\-]+/g, "");
          input = input ? parseInt(input, 10) : 0;

          $this.val(function () {
            return (input === 0) ? "" : input.toLocaleString("en-US");
          });
        });
      },
      resize: function () {
        $('#main-nav').collapse('hide');
      }
    },
    'home': {
      init: function () {
        $('.mortgage-calculator-container').mortgageCalculator();

        createVolumeChart(document.querySelector('.chart-volume'));
        createTransactionChart(document.querySelector('.chart-transaction'));
      }
    }
  };

  var UTIL = {
    fire: function (func, funcname, args) {
      var fire;
      var namespace = Selzer;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function () {
      UTIL.fire('common');

      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      UTIL.fire('common', 'finalize');
    },
    resizeEvents: function () {
      UTIL.fire('common', 'resize');

      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
        UTIL.fire(classnm, 'resize');
      });
    }
  };

  $(document).ready(UTIL.loadEvents);
  $(window).resize(UTIL.resizeEvents);

})(jQuery);
